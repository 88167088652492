.bbs-body {
  text-align: center;
  margin: auto;
  margin-top: 0px;
  padding:20px;
  max-width: 640px;
  background-color: #fff;
  border: 1px solid #eee;
}

.bbs-header {
  text-align: left;
  padding:5px 10px;
  margin-Top: 10px;
  margin-bottom: 2px;
  background-color: #eee;
  font-weight: bolder;
  color: #666;
}

.bbs-form-area{
  text-align:left;
  margin:0px;
  padding:10px;
}

.bbs-content {
  text-align: left;
  margin-top:20px;
}

.bbs-content .head {
  font-size: 8px;
}

.bbs-content .head strong {
  font-size: 12px;
}

.bbs-content .content {
  white-space: pre-wrap;
  font-size: 12px;
  word-break : break-all;
}



.bbs-thread{
  text-align:left;
  margin:0px;
  margin-top:10px;
}

.bbs-thread .content {
  white-space: pre-wrap;
  font-size: 12px;
  margin: 5px;
}

.bbs-thread .info {
  margin: 5px;
  font-size: 10px;
  color: #999;
}

.link {
  position: relative;
  z-index: 1
}

.link:hover {
  opacity: 0.5 ;
}

.link a{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-indent:-999px;
  z-index: 2; /* 必要であればリンク要素の重なりのベース順序指定 */
}

.bbs-desc{
  margin: 10px 0px;
  margin-bottom: 20px;
  text-align: left;
}

.menu {
  text-align:left;
  margin: 20px 0px;
  margin-top:0px;
  font-weight: bolder;
}

.bbs-area {
  margin: 10px 0px;
}


.form-error{
  color: #f00;
  white-space: pre-wrap;
}

.l-border {
  border-left: solid 3px #ddd
}

.y-border {
  border-top: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
}
