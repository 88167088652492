.index-head {
  font-size: 32px;
  font-weight: bolder;
  color: orange;
  margin: 20px 0;
}

.index-head h1 {
  margin: 10px 0px;
}

.index-nav {
  text-align: center;
  border: 1px solid #eee;
  margin: 20px;
  padding: 10px;
}

.index-list{
  text-align:left;
  margin: 20px 10px;
  padding: 10px;
  color: #666;
  border: 1px solid #eee;
  position: relative;
  z-index: 1
}

.index-list:hover {
  opacity: 0.5 ;
}

.index-list a{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-indent:-999px;
  z-index: 2; /* 必要であればリンク要素の重なりのベース順序指定 */
}